export const autoMonetizeAffiliateLink = (url: string, sid: string) => {
  // N.B. The sid allows us to track where the affiliate click originated on our site
  const cj_domain = 'https://www.qksrv.net'
  const cj_PID = 100562975

  const isBookingDotCom = url.indexOf("booking.com") !== -1
  if (!isBookingDotCom) return url

  const sidEncoded = encodeURIComponent(sid)
 
  const monetizedUrl = `${cj_domain}/links/${cj_PID}/type/am/sid/${sidEncoded}/${url}`

  return monetizedUrl
};
