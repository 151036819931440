import {
  NonIntegratedHotelForGuestContract,
  WeddingCustomThemeContract,
} from "@ttoengineering/delphi";
import moment from "moment";
import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import { hotelTypeToPresentationTextMap } from "../../../../api/mappers/hotels/hotels";
import {
  AmplitudeEventType,
  useAmplitudeContext,
} from "../../../../contexts/AmplitudeContext";
import { useCurrencyContext } from "../../../../contexts/CurrencyContext";
import { autoMonetizeAffiliateLink } from "../../../../utils/cj";
import { theme } from "../../../../utils/theme";
import { Button } from "../../../shared/atoms/Button/Button";
import { PerformantImage } from "../../../shared/atoms/PerformantImage/PerformantImage";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";

const ExternalLinkSt = styled.a`
  text-decoration: none;
`;

const CardSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}
`;

const ImageContainerSt = styled.div`
  position: relative;
`;

const DistanceTagSt = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.text01};
  position: absolute;
  top: 10px;
  left: 8px;

  span {
    margin-right: 0.5rem;
  }
`;

interface MobileBookingCodeStProps {
  themeTextColor: string | undefined;
}

const MobileBookingCodeSt = styled.div<MobileBookingCodeStProps>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.text01};
  position: absolute;
  top: 44px;
  left: 8px;

  span {
    margin-right: 0.5rem;
    color: ${({ theme, themeTextColor }) =>
      themeTextColor || theme.colors.brandRed};
    font-family: Poppins, "Montserrat";
  }

  p {
    font-size: 0.725rem;
    line-height: 1.25rem;
    color: ${({ theme, themeTextColor }) =>
      themeTextColor || theme.colors.brandRed};
    margin: 0;
    font-family: Poppins, "Montserrat";

    :nth-of-type(1) {
      display: inline-block;
    }

    :nth-of-type(2) {
      margin-top: 0.25rem;
      font-size: 1rem;
      font-weight: 600;
    }

    :nth-of-type(3) {
      font-style: italic;
      margin-top: 0.25rem;
      color: ${({ theme }) => theme.colors.text03};
    }
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
  }`}
`;

const MainImageSt = styled(PerformantImage)`
  width: 100%;
  height: 240px;
  border-radius: 4px;
  object-fit: cover;

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 240px;
  }`}
`;

const ContentSt = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-direction: row;
  }`}
`;

const LeftContentSt = styled.div`
  padding: 1rem 1.5rem 0 0;
  // width: 52%;

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 1.5rem;
    // width: unset;
  }`}
`;

const RightContentSt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: flex-end;

  p {
    text-align: right;
  }

  button {
    width: 180px;
    margin-top: 0.725rem;
    white-space: nowrap;
  }

  padding: 0 0 1rem 0;

  p:last-of-type {
    display: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: unset;

    p:first-of-type {
      display: none;
    }

    p:last-of-type {
      display: block;
      white-space: nowrap;
    }

    button {
      margin-top: 1.25rem;
    }
  }`}
`;

interface BookingCodeCardStProps {
  themeTextColor: string | undefined;
}

const BookingCodeCardSt = styled.div<BookingCodeCardStProps>`
  display: none;
  box-shadow: ${({ theme }) => theme.boxShadows.shadow1};
  border-radius: 4px;
  padding: 1rem 0.75rem;
  align-items: center;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}

  p {
    font-size: 0.725rem;
    line-height: 1.25rem;
    color: ${({ theme, themeTextColor }) =>
      themeTextColor || theme.colors.brandRed};
    margin: 0;
    font-family: Poppins, "Montserrat";

    :nth-of-type(2) {
      font-size: 1rem;
      font-weight: 600;
    }

    :nth-of-type(3) {
      font-style: italic;
      margin-top: 0.5rem;
      color: ${({ theme }) => theme.colors.text03};
    }
  }
`;

const EmojiIconSt = styled.div`
  font-size: 2rem;
  margin-right: 1rem;
`;

interface Props {
  hotelListing: NonIntegratedHotelForGuestContract;
  customTheme: WeddingCustomThemeContract | null;
}

const GuestPageNonintegratedHotelCard = ({
  hotelListing,
  customTheme,
}: Props) => {
  const router = useRouter();
  const { amplitudeLogEvent } = useAmplitudeContext();
  const { formatPriceWithCurrency } = useCurrencyContext();

  return (
    <ExternalLinkSt
      href={autoMonetizeAffiliateLink(
        hotelListing.nonintegrated_hotel.url,
        `${router.query.slug},${hotelListing.nonintegrated_hotel.id}`
      )}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        amplitudeLogEvent(
          AmplitudeEventType.GP_CLICKED_HOTEL_CARD_BOOK_BUTTON,
          { wedding: router.query.slug }
        );
      }}
    >
      <CardSt>
        <ImageContainerSt>
          <MainImageSt
            cloudinaryURL={hotelListing.nonintegrated_hotel.mainImage?.url}
            alt="Photo of the hotel"
          />
          <DistanceTagSt>
            <span>📌</span> {hotelListing.distanceFromVenueTag}
          </DistanceTagSt>
          {!hotelListing.hidePromotionPill && (
            <MobileBookingCodeSt themeTextColor={customTheme?.textColor}>
              <span>🎁</span>
              <p>{hotelListing.bookingCodeSuperText}</p>
              <p>{hotelListing.bookingCode}</p>
              {hotelListing.expiryText && <p>{hotelListing.expiryText}</p>}
            </MobileBookingCodeSt>
          )}
        </ImageContainerSt>
        <ContentSt>
          <LeftContentSt>
            <UITypography variant="body3" color={theme.colors.text03}>
              {
                hotelTypeToPresentationTextMap[
                  hotelListing.nonintegrated_hotel
                    .type as keyof typeof hotelTypeToPresentationTextMap
                ]
              }
            </UITypography>
            <UITypography variant="body1" fontSize="1.25rem">
              {hotelListing.nonintegrated_hotel.name}
            </UITypography>
            <UISpacer variant="sm" />
            {!hotelListing.hidePromotionPill ? (
              <BookingCodeCardSt themeTextColor={customTheme?.textColor}>
                <EmojiIconSt>🎁</EmojiIconSt>
                <div>
                  <p>{hotelListing.bookingCodeSuperText}</p>
                  <p>{hotelListing.bookingCode}</p>
                  {hotelListing.expiryText && <p>{hotelListing.expiryText}</p>}
                </div>
              </BookingCodeCardSt>
            ) : (
              <UITypography
                variant="body2"
                color={theme.colors.text03}
                lineHeight="1.5rem"
              >
                {hotelListing.nonintegrated_hotel.description}
              </UITypography>
            )}
          </LeftContentSt>
          <RightContentSt>
            <UITypography
              variant="body3"
              color={customTheme?.textColor || theme.colors.brandRed}
            >
              {hotelListing.buttonSubText}
            </UITypography>
            <UITypography variant="h3" lineHeight="2rem" component="p">
              {hotelListing.nightlyBasePriceText
                ? hotelListing.nightlyBasePriceText
                : formatPriceWithCurrency(hotelListing.nightlyBasePricePence)}
              / night
            </UITypography>
            {hotelListing?.nonintegrated_hotel?.minNights &&
              hotelListing?.nonintegrated_hotel?.minNights > 1 && (
                <UITypography
                  variant="body3"
                  lineHeight="1rem"
                  color={theme.colors.text03}
                >{`Min. ${hotelListing?.nonintegrated_hotel?.minNights} nights`}</UITypography>
              )}
            <Button
              styleOverrides={{
                backgroundColor:
                  customTheme?.filledButtonBackgroundColor ||
                  theme.colors.brandRed,
                color: customTheme?.filledButtonTextColor || "white",
              }}
              variant="4"
            >
              Book Hotel
            </Button>
            <UISpacer variant="xs" />
            <UITypography
              variant="body3Bold"
              color={customTheme?.textColor || theme.colors.brandRed}
            >
              {hotelListing.buttonSubText}
            </UITypography>
          </RightContentSt>
        </ContentSt>
      </CardSt>
    </ExternalLinkSt>
  );
};

export default GuestPageNonintegratedHotelCard;
