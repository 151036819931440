import {
  WeddingCustomThemeContract,
  WeddingHotelWithAvailabilityAndRatesContract,
} from "@ttoengineering/delphi";
import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import { hotelTypeToPresentationTextMap } from "../../../../api/mappers/hotels/hotels";
import {
  AmplitudeEventType,
  useAmplitudeContext,
} from "../../../../contexts/AmplitudeContext";
import { useCurrencyContext } from "../../../../contexts/CurrencyContext";
import { theme } from "../../../../utils/theme";
import { Button } from "../../../shared/atoms/Button/Button";
import { PerformantImage } from "../../../shared/atoms/PerformantImage/PerformantImage";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";

const CardSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}
`;

const ImageContainerSt = styled.div`
  position: relative;
`;

const DistanceTagSt = styled.div`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.text01};
  position: absolute;
  top: 10px;
  left: 8px;

  span {
    margin-right: 0.5rem;
  }
`;

const MobileOtherBookingsTagSt = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.text01};
  position: absolute;
  top: 44px;
  left: 8px;

  span {
    margin-right: 0.5rem;
  }

  p {
    line-height: 1rem;
    margin: 0;
  }

  p:nth-of-type(2) {
    font-weight: 600;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
  }`}
`;

const MainImageSt = styled(PerformantImage)`
  width: 100%;
  height: 240px;
  border-radius: 4px;
  object-fit: cover;

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 240px;
  }`}
`;

const ContentSt = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-direction: row;
  }`}
`;

const LeftContentSt = styled.div`
  padding: 1rem 1.5rem 0 0;
  // width: 52%;

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 1.5rem;
    // width: unset;
  }`}
`;

const RightContentSt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: flex-end;

  p {
    text-align: right;
  }

  button {
    width: 180px;
    margin-top: 0.725rem;
    white-space: nowrap;
  }

  padding: 0 0 1rem 0;

  p:last-of-type {
    display: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: unset;

    p:first-of-type {
      display: none;
    }

    p:last-of-type {
      display: block;
      white-space: nowrap;
    }

    button {
      margin-top: 1.25rem;
    }
  }`}
`;

const OtherBookingsCardSt = styled.div`
  display: none;
  box-shadow: ${({ theme }) => theme.boxShadows.shadow1};
  border-radius: 4px;
  padding: 0.125rem 0.75rem;
  align-items: center;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}
`;

interface StrikeStProps {
  themeTextColor: string | undefined;
}

const StrikeSt = styled.span<StrikeStProps>`
  font-size: 1rem;
  color: ${({ theme, themeTextColor }) =>
    themeTextColor || theme.colors.brandRed};
  text-decoration: line-through;
`;

const EmojiIconSt = styled.div`
  font-size: 2rem;
  margin-right: 1rem;
`;

const GuestNamesSt = styled.p`
  font-size: 0.725rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.text02};
  margin: 0;
  font-family: Poppins, "Montserrat";
`;

const MoreNamesSt = styled.p`
  font-size: 0.725rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.text02};
  margin: 0;
  font-family: Poppins, "Montserrat";
  font-weight: 600;
`;

interface Props {
  hotelListing: WeddingHotelWithAvailabilityAndRatesContract;
  customTheme: WeddingCustomThemeContract | null;
  weddingStartDate: string;
  weddingEndDate: string;
  isOnHotelCapsule?: boolean;
}

const GuestPageHotelCard = ({
  hotelListing,
  customTheme,
  weddingStartDate,
  weddingEndDate,
  isOnHotelCapsule,
}: Props) => {
  const router = useRouter();
  const { amplitudeLogEvent } = useAmplitudeContext();
  const { formatPriceWithCurrency } = useCurrencyContext();

  const availabilityCount = hotelListing.availableRooms.length;
  const lowestPricedRoom = hotelListing.roomTypes.sort(
    (a, b) =>
      a.nightlyNegotiatedBasePricePence - b.nightlyNegotiatedBasePricePence
  )[0];

  return (
    <CardSt>
      <ImageContainerSt>
        <MainImageSt
          cloudinaryURL={hotelListing.hotel.mainImage?.url}
          alt="Photo of the hotel"
        />
        <DistanceTagSt>
          <span>📌</span> {hotelListing.distanceFromVenueTag}
        </DistanceTagSt>
        {/* <MobileOtherBookingsTagSt><span>🎉</span> {'blah'} guests booked here</MobileOtherBookingsTagSt> */}
        <MobileOtherBookingsTagSt>
          <span>{hotelListing.rateValidUntil ? "⏰" : "🎉"}</span>
          <div>
            {hotelListing.rateValidUntil ? (
              <>
                <p>Special rate valid until</p>
                <p>{hotelListing.rateValidUntil}</p>
              </>
            ) : (
              <p>Best price guaranteed</p>
            )}
          </div>
        </MobileOtherBookingsTagSt>
      </ImageContainerSt>
      <ContentSt>
        <LeftContentSt>
          <UITypography variant="body3" color={theme.colors.text03}>
            {
              hotelTypeToPresentationTextMap[
                hotelListing.hotel
                  .type as keyof typeof hotelTypeToPresentationTextMap
              ]
            }
          </UITypography>
          <UITypography variant="body1" fontSize="1.25rem">
            {hotelListing.hotel.name}
          </UITypography>
          <UISpacer variant="sm" />
          <OtherBookingsCardSt>
            <EmojiIconSt>
              {hotelListing.rateValidUntil ? "⏰" : "🎉"}
            </EmojiIconSt>
            <div>
              {hotelListing.rateValidUntil ? (
                <>
                  <GuestNamesSt>Special rate valid until</GuestNamesSt>
                  <MoreNamesSt>{hotelListing.rateValidUntil}</MoreNamesSt>
                </>
              ) : (
                <GuestNamesSt>Best price guaranteed</GuestNamesSt>
              )}
            </div>
          </OtherBookingsCardSt>
          {/* <OtherBookingsCardSt>
            <EmojiIconSt>🎉</EmojiIconSt>
            <div>
              <GuestNamesSt>
                <Translate>Best price guaranteed</Translate>
              </GuestNamesSt>
            </div>
          </OtherBookingsCardSt> */}
        </LeftContentSt>
        <RightContentSt>
          <UITypography
            variant="body3"
            color={customTheme?.textColor || theme.colors.brandRed}
          >{`Only ${availabilityCount} left`}</UITypography>
          <UITypography variant="h3" lineHeight="2rem" component="p">
            <StrikeSt themeTextColor={customTheme?.textColor}>
              {formatPriceWithCurrency(
                lowestPricedRoom?.nightlyOriginalBasePricePence
              )}
            </StrikeSt>{" "}
            {formatPriceWithCurrency(
              lowestPricedRoom?.nightlyNegotiatedBasePricePence
            )}
            /night
          </UITypography>
          {hotelListing?.hotel?.minNights &&
            hotelListing?.hotel?.minNights > 1 && (
              <UITypography
                variant="body3"
                lineHeight="1rem"
                color={theme.colors.text03}
              >{`Min. ${hotelListing?.hotel?.minNights} nights`}</UITypography>
            )}
          <Button
            variant="4"
            disabled={availabilityCount === 0}
            styleOverrides={{
              backgroundColor:
                customTheme?.filledButtonBackgroundColor ||
                theme.colors.brandRed,
              color: customTheme?.filledButtonTextColor || "white",
            }}
            onClick={() => {
              amplitudeLogEvent(
                AmplitudeEventType.GP_CLICKED_HOTEL_CARD_BOOK_BUTTON,
                isOnHotelCapsule
                  ? { hotelCapsule: router.query.slug }
                  : { wedding: router.query.slug }
              );
              router.push(
                {
                  pathname: `/accomodations/[accomodationSlug]`,
                  query: {
                    [isOnHotelCapsule ? "hotelCapsule" : "wedding"]:
                      router.query.slug,
                    startDate: weddingStartDate,
                    endDate: weddingEndDate,
                  },
                },
                {
                  pathname: `/accomodations/${hotelListing.hotel.slug}`,
                  query: {
                    [isOnHotelCapsule ? "hotelCapsule" : "wedding"]:
                      router.query.slug,
                    startDate: weddingStartDate,
                    endDate: weddingEndDate,
                  },
                }
              );
            }}
          >
            {availabilityCount > 0 ? "Book Hotel" : "Sold Out"}
          </Button>
          <UISpacer variant="xs" />
          <UITypography
            variant="body3Bold"
            color={customTheme?.textColor || theme.colors.brandRed}
          >{`Only ${availabilityCount} left on our site`}</UITypography>
        </RightContentSt>
      </ContentSt>
    </CardSt>
  );
};

export default GuestPageHotelCard;
